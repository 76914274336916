<template>
	<div>
		<p class="reportsTitle">Aktuális szolgáltatásegyenlegek</p>
		<p class="reportsText">
			Elszámolásos szerződéses konstrukció esetén az alábbi diagram mutatja, hogy gépjárművenként mekkora összegű a havi
			átalánydíjak és a tényleges szerviz- és gumiabroncs ráfordítások különbsége. Pozitív egyenleg megtakarítást jelent, a
			futamidő végén fennálló negatív egyenleg pedig elszámolásra kerül a szerződés lezárásakor. Figyelem! Az aktuális szerviz-
			és gumiabroncs szolgáltatások egyenlegei az egyes szervizpartnerek teljesítést követő számlázása időbeli csúszást
			eredményezhet a szolgáltatási egyenlegeket érintően.
		</p>

		<div id="chart">
			<apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
		</div>
		<div style="text-align:center; padding-bottom:20px;">
			<download-excel
				class="ant-btn ant-btn-primary"
				propName="SERVICE"
				:data="mergedArrays"
				type="csv"
				name="Szerviz és gumiegyenleg.csv"
				style="padding-top:5px;"
				>Mentés Excelbe</download-excel
			>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			series: [],
			mergedArrays: [],

			chartOptions: {
				chart: {
					type: 'bar',
					height: 'auto',

					toolbar: {
						show: true,
						offsetX: -50,
						offsetY: 50,
						tools: {
							download:
								'<svg xmlns="http://www.w3.org/2000/svg" width="50" class="icon_toolbar" height="50" viewBox="0 0 128 128"><path d="M80.016,96h-8.297L63.75,83.039L55.781,96H48l11.367-17.672L48.727,61.734h8.016l7.383,12.328     l7.242-12.328h7.828L68.438,78.727L80.016,96z" style="fill:#007732;"/></g><g><g><path d="M104,80c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S117.255,80,104,80z       M114.882,96.988l-0.113,0.176l-8.232,11.438C105.989,109.468,105.029,110,104,110s-1.989-0.532-2.536-1.397l-8.346-11.614      c-0.529-0.926-0.524-2.073,0.01-2.994c0.535-0.922,1.53-1.494,2.596-1.494H100V86c0-1.654,1.346-3,3-3h2c1.654,0,3,1.346,3,3      v6.5h4.276c1.065,0,2.061,0.572,2.596,1.494C115.406,94.915,115.411,96.063,114.882,96.988z" style="fill:#007732;"/></g><g><g><polygon points="84,125.95 83.95,126 84,126      " style="fill:#FF9A30;"/></g><g><polygon points="114,77 114,76.95 113.95,77      " style="fill:#FF9A30;"/></g><g><path d="M111.071,44.243L71.757,4.929C69.869,3.041,67.357,2,64.687,2H24c-5.514,0-10,4.486-10,10v104       c0,5.514,4.486,10,10,10h59.95l-4-4H24c-3.309,0-6-2.691-6-6V12c0-3.309,2.691-6,6-6h40.687c1.603,0,3.109,0.624,4.242,1.757       l39.314,39.314c1.116,1.117,1.757,2.663,1.757,4.242V72.95l4,4V51.313C114,48.643,112.96,46.132,111.071,44.243z" style="fill:#007732;"/></g><g><polyline points="113.95,77 114,76.95 110,72.95      " style="fill:#FFFFFF;"/></g></g></g></g></g><g id="Powerpoint"/><g id="Powerpoint_download"/><g id="Zip"/><g id="Zip_download"/></svg>',
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							reset: true | '<img src="/static/icons/reset.png" width="20">',
							customIcons: [],
						},
						export: {
							csv: {
								filename: 'Szerviz és gumiegyenleg',
								columnDelimiter: ';',
								headerCategory: '\ufeffcategory',
								headerValue: 'value',
								dateFormatter(timestamp) {
									return new Date(timestamp).toDateString();
								},
							},
						},
						autoSelected: 'zoom',
					},
				},
				colors: ['#1f4e8d', '#a5b8d1'],

				dataLabels: {
					offsetX: 0,
					offsetY: 0,
					formatter: (val) => {
						const valLength = val.toString().length;

						if (valLength == 5 && val > 0) {
							const number = val.toFixed(0);
							const string = number.toString();

							let arr = string.split('');
							arr.splice(2, 0, '.');
							let newarray = arr.join('');

							return newarray + ' Ft';
						} else if (valLength == 6 && val > 0) {
							const number = val.toFixed(0);
							const string = number.toString();

							let arr = string.split('');
							arr.splice(3, 0, '.');
							let newarray = arr.join('');

							return newarray + ' Ft';
						} else if (valLength == 6 && val < 0) {
							const number = val.toFixed(0);
							const string = number.toString();

							let arr = string.split('');
							arr.splice(3, 0, '.');
							let newarray = arr.join('');

							return newarray + ' Ft';
						} else if (valLength == 7 && val > 0) {
							const number = val.toFixed(0);
							const string = number.toString();

							let arr = string.split('');
							arr.splice(1, 0, '.');
							arr.splice(5, 0, '.');
							let newarray = arr.join('');

							return newarray + ' Ft';
						} else if (valLength == 7 && val < 0) {
							const number = val.toFixed(0);
							const string = number.toString();

							let arr = string.split('');
							arr.splice(4, 0, '.');
							let newarray = arr.join('');

							return newarray + ' Ft';
						} else if (valLength == 8 && val < 0) {
							const number = val.toFixed(0);
							const string = number.toString();

							let arr = string.split('');
							arr.splice(2, 0, '.');
							arr.splice(6, 0, '.');
							let newarray = arr.join('');

							return newarray + ' Ft';
						} else return val;
					},
				},
				plotOptions: {
					bar: {
						horizontal: true,

						dataLabels: {
							enabled: true,

							hideOverflowingLabels: false,
						},
					},
				},

				stroke: {
					show: true,
					width: 1,
					colors: ['#fff'],
				},
				xaxis: {
					type: 'category',
					categories: [],
					labels: {
						formatter: (val) => {
							const valLength = val.toString().length;

							if (valLength == 5 && val > 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(2, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else if (valLength == 6 && val > 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(3, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else if (valLength == 6 && val < 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(3, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else if (valLength == 7 && val > 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(1, 0, '.');
								arr.splice(5, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else if (valLength == 7 && val < 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(4, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else if (valLength == 8 && val < 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(2, 0, '.');
								arr.splice(6, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else return val;
						},
					},

					//tickPlacement: 'on',
				},

				tooltip: {
					enabled: true,
					y: {
						formatter: (val) => {
							const valLength = val.toString().length;

							if (valLength == 5 && val > 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(2, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else if (valLength == 6 && val > 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(3, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else if (valLength == 6 && val < 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(3, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else if (valLength == 7 && val > 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(1, 0, '.');
								arr.splice(5, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else if (valLength == 7 && val < 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(4, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else if (valLength == 8 && val < 0) {
								const number = val.toFixed(0);
								const string = number.toString();

								let arr = string.split('');
								arr.splice(2, 0, '.');
								arr.splice(6, 0, '.');
								let newarray = arr.join('');

								return newarray + ' Ft';
							} else return val + ' Ft';
						},
					},
				},
				yaxis: {},
				// title: {
				// 	text: 'Aktuális szolgáltatásegyenlegek ',
				// },
			},
		};
	},
	created() {
		this.$store
			.dispatch('listVehiclesReports', {
				page: 0,
			})
			.then(() => this.calc());
	},

	methods: {
		getVehicles() {
			return this.$store.state.auth.reportsVehicles;
		},
		calc() {
			const services = [];
			const tyres = [];
			const chartXaxis = [];

			// const testVehicles = [
			// 	{
			// 		rendszam: 'RFM-007',
			// 		szervizegyenleg: 123213,
			// 		gumiegyenleg: 123123,
			// 	},
			// 	{
			// 		rendszam: 'RFM-007',
			// 		szervizegyenleg: 123213,
			// 		gumiegyenleg: 123123,
			// 	},
			// ];

			this.getVehicles().forEach((vehicle) => {
				if (vehicle.elszamolasmodja == 'NYILT_VEGU') {
					services.push(vehicle.szervizegyenleg);
					tyres.push(vehicle.gumiegyenleg);
					chartXaxis.push(vehicle.rendszam);
					let newObject = {
						rendszam: vehicle.rendszam,
						szervizegyenleg: vehicle.szervizegyenleg,
						gumiegyenleg: vehicle.gumiegyenleg,
					};
					this.mergedArrays.push(newObject);
				}
			});

			this.chartOptions = {
				...this.chartOptions,
				...{
					xaxis: {
						categories: chartXaxis,
					},
				},
			};

			this.series = [
				{
					name: 'Szervizszolgáltatás egyenleg',
					data: services,
				},
				{
					name: 'Gumiabroncs-szolgáltatás egyenleg',
					data: tyres,
				},
			];
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-info.ant-btn, .ant-btn-primary.ant-btn {
	background: #6d8cb5!important;
	border-color: #6d8cb5 !important;
}
.reportsTitle {
	padding: 1em !important;
	font-family: Helvetica, Arial, sans !important;
	font-size: 14px !important;
	font-weight: 900 !important;
	fill: rgb(55, 61, 63) !important;
}

.reportsText {
	padding: 0.5em 1em;
}

/deep/ .apexcharts-menu-icon {
	height: 4em;
	width: 4em;
}

//  @import '@/styles/components/_reports';
</style>
